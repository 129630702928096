import base from "base-x";
import tinycolor from "tinycolor2";

function normalizeRgbChannel(channel) {
  return 100 * (channel > 0.04045 ? Math.pow((channel + 0.055) / 1.055, 2.4) : channel / 12.92);
}

function normalizeXyzChannel(channel) {
  return channel > 0.008856 ? Math.pow(channel, 1 / 3) : 7.787 * channel + 16 / 116;
}

function rgb2lab(color) {
  let { r, g, b } = tinycolor(color).toRgb();

  r = normalizeRgbChannel(r / 100);
  g = normalizeRgbChannel(g / 100);
  b = normalizeRgbChannel(b / 100);

  let X = r * 0.4124 + g * 0.3576 + b * 0.1805;
  let Y = r * 0.2126 + g * 0.7152 + b * 0.0722;
  let Z = r * 0.0193 + g * 0.1192 + b * 0.9505;

  X = normalizeXyzChannel(X / 95.047);
  Y = normalizeXyzChannel(Y / 100.0);
  Z = normalizeXyzChannel(Z / 108.883);

  return [116 * Y - 16, 500 * (X - Y), 200 * (Y - Z)];
}

export function differenceCiede2000(std, smp, kL = 1, kC = 1, kH = 1) {
  const [lStd, aStd, bStd] = rgb2lab(std);
  const [lSmp, aSmp, bSmp] = rgb2lab(smp);

  const cStd = Math.sqrt(aStd * aStd + bStd * bStd);
  const cSmp = Math.sqrt(aSmp * aSmp + bSmp * bSmp);

  const cAvg = (cStd + cSmp) / 2;

  const G = 0.5 * (1 - Math.sqrt(Math.pow(cAvg, 7) / (Math.pow(cAvg, 7) + Math.pow(25, 7))));

  const apStd = aStd * (1 + G);
  const apSmp = aSmp * (1 + G);

  const cpStd = Math.sqrt(apStd * apStd + bStd * bStd);
  const cpSmp = Math.sqrt(apSmp * apSmp + bSmp * bSmp);

  let hpStd = Math.abs(apStd) + Math.abs(bStd) === 0 ? 0 : Math.atan2(bStd, apStd);
  hpStd += (hpStd < 0) * 2 * Math.PI;

  let hpSmp = Math.abs(apSmp) + Math.abs(bSmp) === 0 ? 0 : Math.atan2(bSmp, apSmp);
  hpSmp += (hpSmp < 0) * 2 * Math.PI;

  const dL = lSmp - lStd;
  const dC = cpSmp - cpStd;

  let dhp = cpStd * cpSmp === 0 ? 0 : hpSmp - hpStd;
  dhp -= (dhp > Math.PI) * 2 * Math.PI;
  dhp += (dhp < -Math.PI) * 2 * Math.PI;

  const dH = 2 * Math.sqrt(cpStd * cpSmp) * Math.sin(dhp / 2);

  const Lp = (lStd + lSmp) / 2;
  const Cp = (cpStd + cpSmp) / 2;

  let hp;
  if (cpStd * cpSmp === 0) {
    hp = hpStd + hpSmp;
  } else {
    hp = (hpStd + hpSmp) / 2;
    hp -= (Math.abs(hpStd - hpSmp) > Math.PI) * Math.PI;
    hp += (hp < 0) * 2 * Math.PI;
  }

  const Lpm50 = Math.pow(Lp - 50, 2);
  const T =
    1 -
    0.17 * Math.cos(hp - Math.PI / 6) +
    0.24 * Math.cos(2 * hp) +
    0.32 * Math.cos(3 * hp + Math.PI / 30) -
    0.2 * Math.cos(4 * hp - (63 * Math.PI) / 180);

  const Sl = 1 + (0.015 * Lpm50) / Math.sqrt(20 + Lpm50);
  const Sc = 1 + 0.045 * Cp;
  const Sh = 1 + 0.015 * Cp * T;

  const deltaTheta =
    ((30 * Math.PI) / 180) * Math.exp(-1 * Math.pow(((180 / Math.PI) * hp - 275) / 25, 2));
  const Rc = 2 * Math.sqrt(Math.pow(Cp, 7) / (Math.pow(Cp, 7) + Math.pow(25, 7)));

  const Rt = -1 * Math.sin(2 * deltaTheta) * Rc;

  return Math.sqrt(
    Math.pow(dL / (kL * Sl), 2) +
      Math.pow(dC / (kC * Sc), 2) +
      Math.pow(dH / (kH * Sh), 2) +
      (((Rt * dC) / (kC * Sc)) * dH) / (kH * Sh),
  );
}

export function getColorCoordinates(index) {
  return `${String.fromCharCode(65 + (index % 5))}${Math.floor(index / 5 + 1)}`;
}

export function isColorBright(value) {
  const r = (value >> 16) & 0xff;
  const g = (value >> 8) & 0xff;
  const b = (value >> 0) & 0xff;

  return tinycolor({ r, g, b }).isLight();
}

export function formatColorString(value) {
  return value.toString(16).padStart(6, "0");
}

export function parseColorString(value) {
  return Number.parseInt(value, 16);
}

export function getStorageValue(key, defaultValue) {
  if (typeof window === "undefined") {
    return defaultValue;
  }

  try {
    let value = localStorage.getItem(key);

    if (typeof value === "string") {
      return JSON.parse(value);
    }
  } catch {} // eslint-disable-line no-empty

  return defaultValue;
}

export function setStorageValue(key, value) {
  if (typeof window === "undefined") {
    return;
  }

  localStorage.setItem(key, JSON.stringify(value));
}

const base62 = base("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ");
const buffer = new Uint8Array(128);

export function encodeScheme(layout, colors) {
  let offset = 0;

  buffer[offset++] = 1;
  buffer[offset++] = layout;
  buffer[offset++] = colors.length;

  const refs = new Array();

  colors.forEach((color) => {
    if (color == null) {
      buffer[offset++] = 0;

      return;
    }

    const index = refs.indexOf(color);

    if (index > -1) {
      buffer[offset++] = 128 + index;

      return;
    }

    if (typeof color === "string") {
      color = Number.parseInt(color, 16);
    }

    refs.push(color);

    const red = (color >> 16) & 0xff;
    const green = (color >> 8) & 0xff;
    const blue = (color >> 0) & 0xff;

    if (red === green) {
      if (red === blue) {
        buffer[offset++] = 1;
        buffer[offset++] = red;

        return;
      }

      buffer[offset++] = 2;
      buffer[offset++] = red;
      buffer[offset++] = blue;

      return;
    }

    if (red === blue) {
      buffer[offset++] = 3;
      buffer[offset++] = red;
      buffer[offset++] = green;

      return;
    }

    if (green === blue) {
      buffer[offset++] = 4;
      buffer[offset++] = red;
      buffer[offset++] = green;

      return;
    }

    buffer[offset++] = 5;
    buffer[offset++] = red;
    buffer[offset++] = green;
    buffer[offset++] = blue;
  });

  return base62.encode(buffer.slice(0, offset));
}

export function decodeScheme(data) {
  const buffer = base62.decode(data);

  let offset = 0;

  // eslint-disable-next-line no-unused-vars
  const version = buffer[offset++];
  const layout = buffer[offset++];
  const length = buffer[offset++];

  const colors = new Array();
  const refs = new Array();

  for (let index = 0; index < length; index += 1) {
    const kind = buffer[offset++];

    switch (kind) {
      case 0: {
        colors.push(null);

        break;
      }

      case 1: {
        const red = buffer[offset++];

        refs.push(colors.push((red << 16) + (red << 8) + red) - 1);

        break;
      }

      case 2: {
        const red = buffer[offset++];
        const blue = buffer[offset++];

        refs.push(colors.push((red << 16) + (red << 8) + blue) - 1);

        break;
      }

      case 3: {
        const red = buffer[offset++];
        const green = buffer[offset++];

        refs.push(colors.push((red << 16) + (green << 8) + red) - 1);

        break;
      }

      case 4: {
        const red = buffer[offset++];
        const green = buffer[offset++];

        refs.push(colors.push((red << 16) + (green << 8) + green) - 1);

        break;
      }

      case 5: {
        const red = buffer[offset++];
        const green = buffer[offset++];
        const blue = buffer[offset++];

        refs.push(colors.push((red << 16) + (green << 8) + blue) - 1);

        break;
      }

      default: {
        colors.push(colors[refs[kind - 128]]);

        break;
      }
    }
  }

  return [layout, colors];
}

export function getLayoutTitle(layout) {
  switch (layout) {
    case 0:
      return "Favorites";

    case 1:
      return "Warframe";

    case 2:
      return "Weapon";
  }
}
