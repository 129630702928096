import { graphql, useStaticQuery } from "gatsby";
import { useHydrateAtoms } from "jotai/utils";

import { colorPalettesAtom } from "../store";

function Root(props) {
  const { allPalettes } = useStaticQuery(graphql`
    query {
      allPalettes: allPalettesJson(
        filter: { options: { visible: { eq: true } } }
        sort: [{ name: ASC }, { options: { legacy: ASC } }]
      ) {
        nodes {
          id
          name
          thumbnail
          options {
            legacy
            platforms
          }
          colors
        }
      }
    }
  `);

  useHydrateAtoms([[colorPalettesAtom, allPalettes.nodes]]);

  return <div className="h-screen overflow-hidden">{props.children}</div>;
}

export default Root;
