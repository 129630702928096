import { atom } from "jotai";
import { filter, find, get, map, sortBy, without } from "lodash";
import tinycolor from "tinycolor2";

import {
  differenceCiede2000,
  formatColorString,
  getStorageValue,
  setStorageValue,
} from "./helpers";

/**
 * The color palettes.
 * @type {Array<Object>}
 */
export const colorPalettesAtom = atom([]);

/**
 * The saved colors.
 * @type {Array<Number>}
 */
export const savedColorsAtom = atom([]);

/**
 * The active color palette ID.
 * @type {?String}
 */
export const activeColorPaletteIdAtom = atom(null);

/**
 * The search query.
 * @type {String}
 */
export const searchQueryAtom = atom("");

/**
 * The colors matches.
 * @type {Object}
 */
export const colorMatchesAtom = atom({});

/**
 * The selected color index.
 * @type {Number}
 */
export const selectedColorIndexAtom = atom(null);

/**
 * The disabled color palettes.
 * @type {Array<String>}
 */
export const disabledPalettesAtom = atom(getStorageValue("disabledPalettes", []));

export const sortedColorPalettesAtom = atom((getAtom) => {
  const disabledPalettes = getAtom(disabledPalettesAtom);
  const colorPalettes = getAtom(colorPalettesAtom);
  const colorMatches = getAtom(colorMatchesAtom);

  const filteredColorPalettes = filter(
    colorPalettes,
    (palette) => !disabledPalettes.includes(palette.id),
  );

  return sortBy(filteredColorPalettes, [
    (colorPalette) => get(colorMatches, [colorPalette.id, "distance"], Infinity),
    (colorPalette) => get(colorPalette, ["name"]),
    (colorPalette) => get(colorPalette, ["options", "legacy"], false),
  ]);
});

export const activeColorPaletteAtom = atom((getAtom) => {
  const activeColorPaletteId = getAtom(activeColorPaletteIdAtom);
  const sortedColorPalettes = getAtom(sortedColorPalettesAtom);

  if (activeColorPaletteId) {
    return find(getAtom(colorPalettesAtom), {
      id: activeColorPaletteId,
    });
  }

  return sortedColorPalettes[0];
});

export const selectedColorAtom = atom((getAtom) => {
  const index = getAtom(selectedColorIndexAtom);
  const activeColorPalette = getAtom(activeColorPaletteAtom);

  if (index == null) {
    return null;
  }

  const {
    colors: { [index]: value },
  } = activeColorPalette;

  return { value, index };
});

export const setColorPalettesAtom = atom(null, (getAtom, setAtom, colorPalettes) => {
  setAtom(colorPalettesAtom, colorPalettes);
});

export const setSavedColorsAtom = atom(null, (getAtom, setAtom, savedColors) => {
  setAtom(savedColorsAtom, savedColors);
});

export const searchQueryChangedAtom = atom(null, (getAtom, setAtom, searchQuery) => {
  const sortedColorPalettes = getAtom(sortedColorPalettesAtom);

  const colorMatches = {};

  if (searchQuery && tinycolor(searchQuery).isValid()) {
    for (const colorPalette of sortedColorPalettes.values()) {
      let closest = null;

      for (const [index, color] of colorPalette.colors.entries()) {
        const distance = differenceCiede2000(formatColorString(color), searchQuery);

        if (!closest || distance < closest.distance) {
          closest = { index, color, distance };
        }
      }

      colorMatches[colorPalette.id] = closest;
    }
  }

  setAtom(colorMatchesAtom, colorMatches);
  setAtom(searchQueryAtom, searchQuery);

  if (searchQuery) {
    setAtom(activeColorPaletteIdAtom, get(sortedColorPalettes, [0, "id"]));
  }
});

export const activeColorPaletteChangedAtom = atom(
  null,
  (getAtom, setAtom, activeColorPaletteId) => {
    setAtom(activeColorPaletteIdAtom, activeColorPaletteId);
  },
);

export const savedColorChangedAtom = atom(null, (getAtom, setAtom, color) => {
  const savedColors = getAtom(savedColorsAtom);

  const newValue = savedColors.includes(color)
    ? without(savedColors, color)
    : savedColors.concat(color);

  setAtom(savedColorsAtom, newValue);
});

export const disabledPaletteChangedAtom = atom(null, (getAtom, setAtom, id) => {
  const disabledPalettes = getAtom(disabledPalettesAtom);

  const newValue = disabledPalettes.includes(id)
    ? without(disabledPalettes, id)
    : disabledPalettes.concat(id);

  setAtom(disabledPalettesAtom, newValue);
  setStorageValue("disabledPalettes", newValue);
});

export const applyPalettePresetAtom = atom(null, (getAtom, setAtom, preset) => {
  const colorPalettes = getAtom(colorPalettesAtom);

  const filteredPalettes = filter(colorPalettes, (palette) => {
    switch (preset) {
      case "ALL":
        return false;

      case "LEGACY_PALETTES":
        return !palette.options.legacy;

      case "NON_LEGACY_PALETTES":
        return palette.options.legacy;
    }

    return true;
  });

  const newValue = map(filteredPalettes, "id");

  setAtom(disabledPalettesAtom, newValue);
  setStorageValue("disabledPalettes", newValue);
});

export const selectColorAtom = atom(null, (getAtom, setAtom, index) => {
  setAtom(selectedColorIndexAtom, getAtom(selectedColorIndexAtom) === index ? null : index);
});
